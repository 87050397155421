var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "questionnaire" },
    [
      _vm.questionnaire
        ? _c("ec-questionnaire", {
            attrs: { content: _vm.questionnaire, "btn-bg-color": "#1fc160" },
            on: { submit: _vm.onSubmit },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }