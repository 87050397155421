/*
 * @Author: chengyuzhang
 * @Date: 2021-02-20 18:04:30
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-03-17 11:13:51
 */

import Questionnaire from 'easycash/common/resource/questionnaire';

import {isYqdCredMex, isOlacred, isMayacash} from 'ssr-common/util/webview/ua';
import Interface from 'ssr-common/util/webview/yqg-webview-interface';
import parseUa from 'ssr-common/vue/mixin/parse-ua';
import {getTitle} from 'ssr-common/vue/ssr/feature/title';

import serverPrefetch from './server-prefetch';

const isMex = ua => isYqdCredMex(ua) || isOlacred(ua) || isMayacash(ua);

export default {
    title() {
        const {title} = this.questionnaire || {};

        return title || this.$t('webview.questionnaire.title');
    },

    mixins: [serverPrefetch('questionnaire'), parseUa()],

    computed: {
        noEdit() {
            return this.$route.meta?.noEdit;
        }
    },

    mounted() {
        const {ua, questionnaire: {errMsg, errCode}} = this;
        if (isMex(ua)) {
            if (errCode) {
                this.$toast.fail({
                    className: 'toast-w',
                    message: errMsg,
                    onClose: () => {
                        Interface.closeWebPage();
                    }
                });
            }
        }
    },

    methods: {
        async initFetch({header}) {
            const {questionnaireId: id} = this.$route.query;
            if (!id) return {};

            const {
                data: {
                    body,
                    status: {detail, code: errCode}
                }
            } = await Questionnaire.get({params: {id}, headers: header}).catch(err => err);
            if (body?.title && this.$ssrContext) {
                this.$ssrContext.title = getTitle('', body.title, ['feat-overseas']);
            }

            return {...body, errMsg: detail, errCode};
        },

        async onSubmit(cond) {
            const {$route: {query: {submitId: id, questionnaireId}}, noEdit} = this;

            let result;

            if (noEdit) {
                const {data: {body}} = await Questionnaire.submitNoIdQuestionnaire({...cond, questionnaireId});
                result = body;
            } else {
                const {data: {body}} = await Questionnaire.submitQuestionnaire({...cond, id});
                result = body;
            }

            if (result) {
                this.submitQuestionnaire();
            }
        },

        submitQuestionnaire() {
            const {ua, noEdit} = this;
            if (noEdit) {
                this.$toast.success({
                    className: 'toast-w',
                    message: 'La encuesta ha sido completado, gracias por sus comentarios.',
                    onClose: () => {
                        if (isMex(ua)) {
                            Interface.closeWebPage();
                        }
                    }
                });
            } else {
                Interface.callHandler('submitQuestionnaire');
            }
        }
    }
};
