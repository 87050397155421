<!-- @Author: ruiwang -->
<!-- @Date: 2020-3-24 17:33:53 -->
<!-- @Last Modified by: lijiahang -->
<!-- @Last Modified time: 2023-11-09 13:25:14 -->

<template>
    <div
        class="ec-questionnaire"
        :style="{'background-image': content.status === 'ENABLED' && bg ? `url(${bg})` : 'none'}"
    >
        <template v-if="content.status === 'ENABLED' && mounted">
            <p
                v-if="questionnaire.description"
                class="description"
                v-html="questionnaire.description"
            />
            <ul class="question">
                <li
                    v-for="({question, options, type, hide: hideConfig, show: showConfig, number}, qIndex) in list"
                    v-show="(!hideConfig && !showConfig) || !hide({hideConfig, showConfig})"
                    :key="qIndex"
                    :ref="`question-${number}`"
                    class="question-item"
                >
                    <div class="q-name">
                        {{ question }}
                    </div>
                    <template v-for="(op, idx) in options">
                        <div
                            :key="idx"
                            class="q-option"
                            @click="onSelect(qIndex, idx, op)"
                        >
                            <div
                                class="q-option-check"
                                :class="{
                                    'q-option-check-active': isActive(qIndex, op),
                                    'multiple': type === 'multiple'
                                }"
                            >
                                <slot name="check-mark">
                                    <img
                                        class="checkmark"
                                        src="./img/checkmark.svg"
                                    >
                                </slot>
                            </div>
                            <div class="q-option-text">
                                {{ op.label || op }}
                            </div>
                        </div>
                        <div
                            v-if="op.needInput && isActive(qIndex, op)"
                            :key="`extra_wrapper_${idx}`"
                        >
                            <textarea
                                :key="`extra_${idx}`"
                                :ref="`extra_${number}_${idx}`"
                                v-model="list[qIndex].input"
                                :rows="1"
                                :placeholder="op.placeholder"
                                class="extra"
                                @input="onInput(number, idx)"
                            />
                            <textarea
                                :key="`extra_${idx}_hidden`"
                                :ref="`extra_${number}_${idx}_hidden`"
                                v-model="list[qIndex].input"
                                :rows="1"
                                class="extra extra_hidden"
                            />
                        </div>
                    </template>
                </li>
            </ul>

            <div class="btn-box">
                <button
                    :disabled="disabledSubmit"
                    :style="{'background-color': disabledSubmit ? '#ccc' : btnBgColor}"
                    class="btn-submit"
                    @click="onSubmit"
                >
                    {{ $t('webview.questionnaire.submit') }}
                </button>
            </div>
        </template>
        <slot
            v-else-if="mounted"
            name="empty"
        >
            <div class="empty">
                <img
                    src="./img/empty.png"
                    alt=""
                >
                <div>{{ $t('webview.questionnaire.expired') }}</div>
            </div>
        </slot>
    </div>
</template>

<script>
import _ from 'underscore';

export default {
    name: 'EcQuestionnaire',

    props: {
        content: {
            type: Object,
            default: () => ({})
        },

        btnBgColor: {
            type: String,
            default: '#03DA8B'
        }
    },

    data() {
        return {
            list: this.content.list,
            questionnaire: {},
            bg: undefined,
            hasSubmitted: false,
            mounted: false
        };
    },

    computed: {
        valid() {
            return this.isFormValid();
        },

        disabledSubmit() {
            const {valid, hasSubmitted} = this;

            return valid !== true || hasSubmitted;
        },

        aliasMap() {
            const aliasMap = {};
            const {list} = this;
            list.forEach(({show}, idx) => {
                if (show) {
                    show.reduce((acc, cur) => {
                        if (!acc[cur.number]) acc[cur.number] = [];

                        acc[cur.number].push(idx + 1);

                        return acc;
                    }, aliasMap);
                }
            });

            return aliasMap;
        }
    },

    watch: {
        content: {
            handler: 'initQuestionnaire'
        }
    },

    created() {
        this.initQuestionnaire();
    },

    mounted() {
        this.mounted = true;
    },

    methods: {
        clearChild(idx) {
            const {aliasMap} = this;
            const num = idx + 1;

            const cur = aliasMap[num];
            if (cur?.length) {
                cur.forEach(item => {
                    const pre = this.list[item - 1];
                    this.$set(this.list, item - 1, {...pre, code: undefined, input: undefined});
                    this.clearChild(item - 1);
                });
            }
        },

        initQuestionnaire() {
            const {content: {questionnaireContent, backgroundImageUrl}} = this;
            this.bg = backgroundImageUrl;
            try {
                this.questionnaire = JSON.parse(questionnaireContent);
            } catch {
                this.questionnaire = {};
            }

            this.list = this.questionnaire.list?.map((item, index) => {
                const {options} = item;
                if (item.randomOptions) {
                    let back = options.length;
                    let temp = -1;
                    let i = 0;
                    while (back) {
                        i = Math.floor(Math.random() * back);
                        back -= 1;
                        temp = options[back];
                        options[back] = options[i];
                        options[i] = temp;
                    }
                }

                return {
                    number: index + 1,
                    ...item,
                    options
                };
            });
        },

        hide({hideConfig, showConfig}) {
            let config = hideConfig;
            let isShow = false;
            if (showConfig) {
                config = showConfig;
                isShow = true;
            }

            if (!config) return false;
            config = [].concat(config);
            const {list} = this;

            const codeItemChecked = config.some(({number, value}) => {
                const curQuestion = list[number - 1];

                return curQuestion.code && curQuestion.code.includes(value);
            });

            return isShow ? !codeItemChecked : codeItemChecked;
        },

        isFormValid() {
            const {list} = this;
            if (!list) return false;
            for (let i = 0; i < list.length; i += 1) {
                const {hide: hideConfig, show: showConfig, code, needInput, input, number} = list[i];
                if (!(this.hide({hideConfig, showConfig}) || (code && code.length && (!needInput || input)))) {
                    return number;
                }
            }

            return true;
        },

        isActive(qIndex, op) {
            const {list} = this;

            return list[qIndex].code && list[qIndex].code.includes(op.label || op);
        },

        onSelect(index, optionIndex, option) {
            this.clearChild(index);
            const question = this.list[index];
            const {type, code: preAnswer = [], maxSelected} = question;
            const value = option.value || option.label || option;
            let code = [value];
            let {needInput} = question;
            if (type === 'multiple') {
                if (!maxSelected || preAnswer.length < Number(maxSelected)) {
                    code = preAnswer.includes(value)
                        ? _.without(preAnswer, value)
                        : _.union(preAnswer, [value]);
                } else {
                    code = preAnswer.includes(value)
                        ? _.without(preAnswer, value)
                        : preAnswer;
                }

                if (option.needInput) {
                    needInput = code.includes(value);
                }
            } else {
                needInput = option.needInput;
            }

            this.$set(this.list, index, {
                ...question,
                code,
                needInput
            });
        },

        onInput(number, idx) {
            const [input] = this.$refs[`extra_${number}_${idx}`];
            const [inputHidden] = this.$refs[`extra_${number}_${idx}_hidden`];
            this.$nextTick(() => {
                input.style.height = `${inputHidden.scrollHeight}px`;
            });
        },

        formatAnswer() {
            const {list} = this;

            return list.filter(({hide: hideConfig, show: showConfig}) => !this.hide({hideConfig, showConfig}))
                .map(({code, input, number}) => ({
                    number,
                    code,
                    input
                }));
        },

        alertEmpty(nubmer) {
            const [ref] = this.$refs[`question-${nubmer}`];
            ref.scrollIntoView();
            ref.classList.add('alert');
            setTimeout(() => {
                ref.classList.remove('alert');
            }, 2400);
        },

        onSubmit() {
            const {hasSubmitted, valid} = this;
            if (hasSubmitted) {
                return;
            }

            if (valid !== true) {
                this.alertEmpty(this.valid);

                return;
            }

            const answer = this.formatAnswer();
            const cond = {
                answer,
                status: 'COMPLETED'
            };
            this.hasSubmitted = true;
            this.$emit('submit', cond);
        }
    }
};
</script>

<style lang="scss" scoped>
    .ec-questionnaire {
        padding: 20px;
        min-height: 100vh;
        color: #333;
        font-size: 14px;
        line-height: 18px;
        background-size: cover;
        background-repeat: no-repeat;

        ul {
            margin: auto;
            padding: 0;
        }

        li {
            list-style: none;
        }

        .description {
            color: #999;
            margin: 0 auto;
        }

        .empty {
            padding-top: 30px;
            text-align: center;
            font-size: 18px;
            font-weight: bold;

            img {
                width: 70px;
                height: auto;
                margin: 0 auto 10px;
            }
        }

        .question {
            &-item {
                margin-top: 30px;

                &.alert {
                    @keyframes fade {
                        from {
                            color: #333;
                        }

                        50% {
                            color: #00c989;
                        }

                        to {
                            color: #333;
                        }
                    }

                    animation: fade 800ms infinite;
                }
            }

            .q-name {
                font-weight: bold;
            }

            .q-option {
                margin-top: 10px;
                display: flex;
                align-items: flex-start;

                &-check {
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    box-sizing: border-box;
                    border: 2px solid #dedede;
                    margin-right: 10px;
                    margin-top: 3px;

                    .checkmark {
                        width: 100%;
                        height: auto;
                        display: none;
                    }

                    &.multiple {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 2px;
                        overflow: hidden;

                        .checkmark {
                            width: 16px;
                            height: 16px;
                            max-width: none;
                            flex-shrink: 0;
                        }
                    }
                }

                &-check-active {
                    border: none;

                    .checkmark {
                        display: block;
                    }
                }

                &-text {
                    flex: 1;
                }
            }

            .extra {
                font-size: 18px;
                font-weight: bold;
                background: transparent;
                width: 100%;
                border-width: 0 0 1px;
                border-color: #dedede;
                margin-top: 10px;
                resize: none;
                border-radius: 0;

                &:focus {
                    outline: none;
                }

                &::placeholder {
                    color: #ccc;
                    font-weight: 400;
                    font-size: 16px;
                }
            }

            .extra_hidden {
                height: 0;
                border: 0;
                padding: 0;
                margin-top: 0;
            }
        }

        .btn-submit {
            width: 100%;
            height: 60px;
            outline: none;
            font-size: 18px;
            font-weight: bold;
            color: #fff;
            border-radius: 8px;
            margin-top: 30px;
            border: none;

            &:disabled {
                background-color: #ccc;
            }
        }
    }
</style>
