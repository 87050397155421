<!-- @author ruiwang -->
<!-- @email ruiwang@yangqianguan.com -->
<!-- @date 2020-3-24 21:37:17 -->
<!-- @desc questionnaire.vue -->

<template>
    <div class="questionnaire">
        <ec-questionnaire
            v-if="questionnaire"
            :content="questionnaire"
            btn-bg-color="#1fc160"
            @submit="onSubmit"
        />
    </div>
</template>

<script>
import EcQuestionnaire from 'easycash/common/component/ec-questionnaire';
import questionnaireManage from 'easycash/common/mixin/async-questionnaire-manage';

export default {
    name: 'Questionnaire',

    components: {EcQuestionnaire},

    mixins: [questionnaireManage]
};
</script>

<style lang="scss">
    .questionnaire {
        padding: 0;
        background-color: #f5fbfb;
    }
</style>
